import request from '../utils/request';
import qs from 'qs';

let domain = 'https://api.xgp365.com/api/app.v1';

// if(process.env.VUE_APP_ENV == "prod"){
//     domain = 'https://api.xgp365.com/api/app.v1';
// }else{
//     domain = 'http://test.api.xgp365.com/api/app.v1';
// }


function getToken (){
    return '';
}

export const get = (path,query,headers) => {
    let token = getToken();
    if(token){
        query['token'] = token;
    }
    if(!headers){
        headers = {};
    }
    return request({
        url: domain + path,
        method: 'get',
        params: query,
        headers:headers,
    });
};

export const post = (path,data,query,headers) => {
    let token = getToken();
    if(!query){
        query = {};
    }
    if(token){
        data['token'] = token;
        query['token'] = token;
    }
    if(!headers){
        headers = {};
    }
    if(headers['content-type'] != 'multipart/form-data'){
        data = qs.stringify(data);
    }
    return request({
        url: domain + path,
        method: 'post',
        data:data,
        params:query,
        headers:headers,
    });
};

export const upload = () =>{
    return domain + '/upload?token='+getToken();
}

export const _domain = ()=>{
    return domain;
};